import React from "react";
import { useSelector } from "react-redux";
import Layout from "../../layouts/en";
import SEO from "components/seo";
import Reward from "../../services/Rewards";
import langData from "../../data/static-pages/pages/rewards/en";
import { IN_PROGRESS } from "state/constants/actions";

const RewardPage = ({ ...props }) => {
  const { uiState } = useSelector((state) => state.rewards);

  return (
    <Layout location={props.location} footer_margin={uiState === IN_PROGRESS}>
      <SEO
        title="Redeem your stay at AYANA Rewards | Hotel Loyalty Program "
        keyword="product,reward,hotel,resort,ayana"
        decription="Redeem your points that you have earned! Treat yourself for a stay at our resort in Bali or Komodo. Or dine at our properties. You can also redeem for a gift voucher. Perfect gifts awaits!"
      />

      <Reward
        baseUrl={props.location.href}
        params={props.location.search}
        langKey={props.pageContext.langKey}
        pathname={props.location.pathname}
        loginUrl={"/login"}
        redemptionUrl={"/member/redemption"}
        langData={langData}
      />
    </Layout>
  );
};

export default RewardPage;
