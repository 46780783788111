module.exports = {

    'title': 'Redeem',
    'description': 'Reward yourself with exciting voucher, free nights, exclusive access, etc.',
    'label_filter': 'FILTER',
    'label_point': 'Points',
    'label_sperator_point': 'to',
    'btn_label_view': 'VIEW',
    'label_location': 'Reward Location',
    'label_category': 'Reward Category',
    'label_point_required': 'Points required: ',
    'label_point': 'Points',
    'placeholder_location': 'All Properties',
    'placeholder_category': 'All Category',
    'crumb_home_label': 'HOME',
    'crumb_reward_label': 'REWARD',
    'detail_point_required': 'POINTS REQUIRED',
    'placeholder_quantity': 'Please select',
    'label_btn_redeem_now': 'Redeem Now',
    'label_popup_title': 'CONFIRM REDEEM',
    'text_confirmation': 'Would you like to redeem ',
    'placeholder_note': 'Inform us when you want to use this voucher',
    'label_btn_cancel': 'CANCEL',
    'label_btn_ok': 'REDEEM',
    'label_ribbon_special_offer': 'Special Offers'
}